import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const mcqApi = createApi({
  reducerPath: 'mcqApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      // Get the token from the Redux state (or another storage mechanism)
      // console.log('state ', getState());
      const token = getState()?.auth?.authToken; 
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['MCQ'],
  endpoints: (build) => ({
    getMCQs: build.query({
      query: (params) => {
        const queryString = new URLSearchParams(params).toString();
        return `mcq/list?${queryString}`;
      },
      providesTags: ['MCQ'],
    }),
    createMCQ: build.mutation({
      query: (newMCQ) => ({
        url: 'mcq/create',
        method: 'POST',
        body: newMCQ,
      }),
      invalidatesTags: ['MCQ'],
    }),
    updateMCQ: build.mutation({
      query: ({ id, ...updatedMCQ }) => ({
        url: `mcq/update/${id}`,
        method: 'PUT',
        body: updatedMCQ,
      }),
      invalidatesTags: ['MCQ'],
    }),
    deleteMCQ: build.mutation({
      query: (id) => ({
        url: `mcq/delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['MCQ'],
    }),
  }),
});

export const { useGetMCQsQuery, useCreateMCQMutation, useUpdateMCQMutation, useDeleteMCQMutation } = mcqApi;