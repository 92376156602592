import React,{useState} from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  Rating,
  useTheme,
  useMediaQuery,
  Modal,
  TextField,
  Stack,
  styled,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from "@mui/material";
import { useGetCustomersQuery } from "state/api";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import {useNavigate} from 'react-router-dom';
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  outline: 'none',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

const Customers = () => {
  const theme    = useTheme();
  const navigate = useNavigate();
  const { data, isLoading } = useGetCustomersQuery();
  // console.log("data", data);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const defaultTheme = {
    backgroundColor: theme.palette.background.alt,
    color: theme.palette.text.primary
  };

  const [name,setName]         = useState('')
  const [phone,setPhone]       = useState('')
  const [password,setPassword] = useState('')
  const [email,setEmail]       = useState('')
  const [status,setStatus]     = useState('')
  const [country,setCountry]   = useState('')
  const [image,setImage]       = useState('')

  const [selectedRow, setSelectedRow] = React.useState(null);

  const statusList     = ['Deactive','Active'];
  const occupationList = ['Doctor','Engineer','Army'];
  const countryList    = ['Bangladesh','India','America','Russsia'];

  function handleViewClick(params){
    navigate('/user',{
      state: params
    })
  }
  function handleEditClick(params){
    navigate('/edit-customer',{
      state: params
    })
  }

  const saveData = ()=>{}

  const columns = [
    // {
    //   field: "_id",
    //   headerName: "ID",
    //   flex: 1,
    // },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 0.5,
      // renderCell: (params) => {
      //   return params.value.replace(/^(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
      // },
    },
    {
      field: "country.name",
      headerName: "Country",
      flex: 0.4,
    },
    {
      field: "occupation",
      headerName: "Occupation",
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 0.5,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Button variant="text" color="secondary" onClick={() => handleViewClick(params.row)}>
              View
            </Button>
            <Button variant="text" color="secondary" onClick={()=>handleEditClick(params.row)}>
              Edit
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <Box sx={{ padding:{ xs:1, md:3} }}>
      <Header title="CUSTOMERS" subtitle="List of Customers" />

      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data || []}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default Customers;
