import React,{useState} from "react";
import { useCreateTeacherMutation, useDeleteTeacherMutation, useGetTeacherQuery, useUpdateTeacherMutation } from "state/api";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  Rating,
  useTheme,
  useMediaQuery,
  Modal,
  TextField,
  Stack,
  styled,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Navigate, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const Teachers = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { data, isLoading, refetch } = useGetTeacherQuery();
  console.log(isLoading);

  const [createTeacher] = useCreateTeacherMutation();
  const [updateTeacher] = useUpdateTeacherMutation();
  const [deleteTeacher] = useDeleteTeacherMutation();


  // const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const defaultTheme = {
    backgroundColor: theme.palette.background.alt,
    color: theme.palette.text.primary
  };

  const statusList     = ['Active','Deactive'];
  const countryList    = ['Bangladesh','India','USA','China','Malaysia'];

  const [pageWindow, setPageWindow] = useState('list');
  const [formData, setFormData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const getFormData =  (e) => {
    setFormData({...formData, [e.target.name]: e.target.value });
  }

  

   // State to manage socials array
   const [socials, setSocials] = useState([
    { platform: '', url: '' }, // Initial entry
   ]);

  // Handle change for individual social input
  const handleChange = (index, field, value) => {
    const updatedSocials = [...socials];
    updatedSocials[index][field] = value;
    setSocials(updatedSocials);
  };

  // Add a new empty social entry
  const handleAddSocial = () => {
    setSocials([...socials, { platform: '', url: '' }]);
  };

  // Remove a social entry
  const handleRemoveSocial = (index) => {
    const updatedSocials = socials.filter((_, i) => i !== index);
    setSocials(updatedSocials);
  };

  const handleCancelClick = () => {
    setPageWindow('list');
  }

  const handleAddClick = () => {
    setPageWindow('add_edit');
    setFormData({});
    setSocials([{platform:'', url:''}]);
    setIsEditing(false);
    // navigate('/user',{
    //   state: params
    // })
  }
  const handleViewClick = (params) => {
    setPageWindow('view');
    setFormData(params);
    // navigate('/user',{
    //   state: params
    // })
  }
  const handleEditClick = (params) => {
    console.log('params', params);
    setPageWindow('add_edit');
    setFormData(params);
      // const editableRow = data.filter( (e) => e._id==id);
    setIsEditing(true);

    // navigate('/add-edit-teacher',{
    //   state: params
    // })
  }

  // add/update submit button handler
  const handeSubmit = async (e) =>{
    e.preventDefault();
    let response = {};

    const submissionData = {
      ...formData,
      socials,
    }
    // unwrap to get instant response from resp.data
    if ( isEditing ) {
      response = await updateTeacher(submissionData).unwrap();
    } else {
      response = await createTeacher(submissionData).unwrap();
    }
    // console.log(response);
    if ( response.status == 'success' ){
      toast.success(response.message);
      setPageWindow('list');
    } else {
      toast.error(response.message);
    }
  }

  const handleDeleteClick = async (e) => {
    if ( window.confirm("Are you sure to delete?") ) {
      const resp = await deleteTeacher(e._id).unwrap();
      const {status, message} = resp;
      if ( status == 'success' ){
        toast.success(message);
      } else {
        toast.error(message);
      }
    }
    return false;
  
  }
  
  const columns = [
    // {
    //   field: "_id",
    //   headerName: "ID",
    //   flex: 1,
    // },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 0.5,
      // renderCell: (params) => {
      //   return params.value.replace(/^(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
      // },
    },
    {
      field: "country",
      headerName: "Country",
      flex: 0.4,
    },
    {
      field: "expertise",
      headerName: "Expertise",
      flex: 1,
    },
    {
      field: "organization",
      headerName: "Organization",
      flex: 0.5,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            {/* <Button variant="text" color="secondary" onClick={() => handleViewClick(params.row)}>
              View
            </Button> */}
            <Button variant="text" color="secondary" onClick={()=>handleEditClick(params.row)}>
              Edit
            </Button>
            <Button variant="text" color="secondary" onClick={() => handleDeleteClick(params.row)}>
              Delete
            </Button>
          </Box>
        );
      },
    },
  ];


  if ( pageWindow=='add_edit' ) {
    return (
      <Box sx={{ padding:{ xs:1, md:3} }}>
  
        { isEditing ? 
              <Header title="Teacher" subtitle="Edit Teacher" />
              :
              <Header title="Teacher" subtitle="Add Teacher" />
        }
  
  
  
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
  
        <Box>
          <TextField label="Name" sx={{width: '100%',mb: 2}} name="name" value={formData && formData.name} onChange={getFormData} />
  
          <TextField label="Email" type="email" sx={{width: '100%',mb: 2}} name="email" value={formData && formData.email} onChange={getFormData} />
  
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              mb: 2
            }}
          > 
            <TextField label="Phone" sx={{width: '50%'}} name="phone" value={formData && formData.phone} onChange={getFormData} />
  
            <TextField label="Password" type="password" sx={{width: '50%'}} name="password" value={formData && formData.password} onChange={getFormData} />
          </Stack>
  
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              mb: 2
            }}
          > 
            <TextField label="Expertise In" sx={{width: '50%'}} name="expertise" value={formData && formData.expertise} onChange={getFormData} />
  
            <TextField label="Organization" type="text" sx={{width: '50%'}} name="organization" value={formData && formData.organization} onChange={getFormData} />
          </Stack>
  
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              mb: 2
            }}
          > 
  
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="status"
                value={formData && formData.status}
                label="Status"
                onChange={getFormData}
              >
                {
                  statusList.map((item,index)=>{
                    return(
                      <MenuItem key={index} value={item}>{item}</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
  
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="country"
                value={formData && formData.country}
                label="Country"
                onChange={getFormData}
              >
                {
                  countryList.map((item,index)=>{
                    return(
                      <MenuItem key={index} value={item}>{item}</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
          
          </Stack>
  
          {/* <Box>
            {
              socialLinks.map( (item, i)=> (
                <TextField key={i} label={item.label} sx={{width: '100%',mb: 2}} name={`socials[${item.name}]`} onChange={getFormData} />
              ))
            }
          </Box> */}
  
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} marginBottom={2}> 
                <h6>Social Links  </h6>
                <Button size="small"  variant="contained" onClick={handleAddSocial}>
                  Add Social Link
                </Button>
            </Stack>
            {socials.map((social, index) => (
              <div key={index} style={{ marginBottom: '1rem' }}>
                <TextField
                  label="Platform ex: Facebook, Linkedin, etc"
                  variant="outlined"
                  value={social.platform}
                  onChange={(e) => handleChange(index, 'platform', e.target.value)}
                  sx={{ marginRight: '1rem', width: '30%' }}
                />
                <TextField
                  label="URL"
                  variant="outlined"
                  value={social.url}
                  onChange={(e) => handleChange(index, 'url', e.target.value)}
                  sx={{ marginRight: '1rem', width: '50%' }}
                />
                <Button
                  variant="outlined"
                  color="error"
                  sx={{marginTop:1}}
                  onClick={() => handleRemoveSocial(index)}
                >
                  Remove
                </Button>
              </div>
            ))}
            
  
          {/* <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            sx={{mb: 2,width: '100%'}}
          >
            Upload files
            <VisuallyHiddenInput
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </Button> */}
  
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              mb: 2
            }}
          > 
          
          <Button variant="contained" sx={{width: "10%",backgroundColor: 'red',color: 'white',fontWeight: 'bold'}} onClick={handleCancelClick}>Cancel</Button>
          <Button onClick={handeSubmit} variant="contained" sx={{width: "100%",backgroundColor: 'green',color: 'white',fontWeight: 'bold',mb: 2}}>Save</Button>
  
          </Stack>
        </Box>
          
        </Box>
      </Box>
    )
  }

  return (
    <Box sx={{ padding:{ xs:1, md:3} }}>
      <Header title="Teachers" subtitle="Managing list of teachers" />
      <Box sx={{ width: '100%', textAlign: "right"}}>
      <Button onClick={handleAddClick} sx={{...defaultTheme,fontWeight: 'bold'}}>Add Teacher</Button>
      </Box>
     
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={data || []}
          columns={columns}
          components={{
            ColumnMenu: CustomColumnMenu,
          }}
        />


      </Box>
    </Box>
  );
};

export default Teachers;
