import React, { useState } from 'react';
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Stack
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import {
  useGetMCQsQuery,
  useCreateMCQMutation,
  useUpdateMCQMutation,
  useDeleteMCQMutation,
} from '../../state/mcqApi'; 
import { useGetCategoryQuery } from '../../state/api';
import { toast } from 'react-toastify';

//const competitionType = [ 'Math', 'English', 'General', 'Ludu' ]
const statuses = ['Under Review', 'Publish'];
//const difficulties = ['easy', 'medium', 'hard'];

const MCQManagement = () => {
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState(null);
  const [formData, setFormData] = useState({
    question: '',
    options: [
      { text: '', isCorrect: false },
      { text: '', isCorrect: false },
      { text: '', isCorrect: false },
      { text: '', isCorrect: false },
    ],
    correctAnswer: '',
    status: '',
    refLink: '',
    categoryId: '',
  });
  const [currentId, setCurrentId] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const choice = [ 'Option A', 'Option B', 'Option C', 'Option D'];

  const [searchForm, setSearchForm] = useState({
    categoryId:'',
    status: ''
  });

  const { data: apiResponse, isLoading } = useGetMCQsQuery(searchForm);
  const { data: apiCatagory } = useGetCategoryQuery();

  console.log('mcqList', apiResponse);

  const mcqs = Array.isArray(apiResponse?.data) ? apiResponse.data : [];
  const categories = Array.isArray(apiCatagory) ? apiCatagory : [];


  // console.log(mcqs);

  const [createMCQ] = useCreateMCQMutation();
  const [updateMCQ] = useUpdateMCQMutation();
  const [deleteMCQ] = useDeleteMCQMutation();

  const handleSearch = () => {

  }
  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleOpen = (mcq = null) => {
    if (mcq) {
      setFormData({ ...mcq, categoryId: mcq.categoryId._id });
      setCurrentId(mcq._id);
    } else {
      setFormData({
        question: '',
        options: [
          { text: '', isCorrect: false },
          { text: '', isCorrect: false },
          { text: '', isCorrect: false },
          { text: '', isCorrect: false },
        ],
        correctAnswer: '',
        status: '',
        refLink: '',
        categoryId: '',
      });
      setCurrentId(null);
    }
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  // Confirmation Dialog Handlers
  const openConfirmationDialog = (action) => {
    setConfirmationAction(() => action);
    setConfirmationOpen(true);
  };

  const handleConfirm = () => {
    confirmationAction();
    setConfirmationOpen(false);
  };

  const handleCancelConfirmation = () => {
    setConfirmationAction(null);
    setConfirmationOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleOptionChange = (index, field, value) => {
    const updatedOptions = formData.options.map((option, i) => 
      i === index 
        ? { ...option, [field]: field === 'isCorrect' ? value === 'true' : value } 
        : option
    );
  
    setFormData((prev) => ({ ...prev, options: updatedOptions }));
  };

  const handleSubmit = async () => {
    const payload = {
      ...formData,
      correctAnswer: formData.options.find((option) => option.isCorrect)?.text || '',
    };

    let resp = {};
    try {
      if (currentId) {
        resp = await updateMCQ({ id: currentId, ...payload }).unwrap();
      } else {
        resp = await createMCQ(payload).unwrap();
      }
      const {status, message} = resp;
      toast.success(message);
      setOpen(false);
    } catch (error) {
      console.log(error)
      toast.error(error.data.message);
    }
   
  
    // openConfirmationDialog(async () => {
    //   if (currentId) {
    //     await updateMCQ({ id: currentId, ...payload });
    //   } else {
    //     await createMCQ(payload);
    //   }

    //   setOpen(false);
    // });
  };

  // const handleDelete = async (id) => {
  //   openConfirmationDialog(async () => {
  //     await deleteMCQ(id);
  //   });
  // };

  return (
    <Box sx={{ padding:{ xs:1, md:3} }}>
      
      <div className='row'>
        <div className='col-md-3'>
            <Button variant="contained" color="primary" onClick={() => handleOpen()}>
            Create New MCQ
          </Button>
        </div>
        <div className='col-md-9'>
          <div className='row'>
            <div className='col-md-8'></div>
            <div className='col-md-2'>
              <TextField
                select
                margin="dense"
                name="categoryId"
                label="All Category"
                fullWidth
                value={searchForm.categoryId}
                onChange={handleSearchChange}
                size='small'
              >
                <MenuItem key={1211} value="">
                    All Categories
                </MenuItem>
                {categories.map((obj, k) => (
                  <MenuItem key={k} value={obj._id}>
                    {obj.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className='col-md-2'>
              <TextField
                select
                margin="dense"
                name="status"
                label="All Status"
                fullWidth
                value={searchForm.status}
                onChange={handleSearchChange}
                size='small'
              >
                <MenuItem key={121143} value="">
                    All Status
                </MenuItem>
                {statuses.map((val, k) => (
                  <MenuItem key={k} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </div>
      </div>
      

      {/* Table */}
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>SerialNo</TableCell>
              <TableCell>Options</TableCell>
              <TableCell>Correct Answer</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={5}>Loading...</TableCell>
              </TableRow>
            ) : (
              mcqs.map((mcq) => (
                <TableRow key={mcq._id}>
                  <TableCell>{mcq.question}</TableCell>
                  <TableCell>{mcq.categoryId.name}</TableCell>
                  <TableCell>{mcq.serialNo}</TableCell>
                  <TableCell>
                    {mcq.options.map((option) => `${option.text}`).join(', ')}
                  </TableCell>
                  <TableCell>{mcq.correctAnswer}</TableCell>
                  <TableCell>{mcq.status}</TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => handleOpen(mcq)}>
                      <Edit />
                    </IconButton>
                    {/* <IconButton color="secondary" onClick={() => handleDelete(mcq._id)}>
                      <Delete />
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{currentId ? 'Edit MCQ' : 'Create MCQ'}</DialogTitle>
        <DialogContent>
          <TextField
            select
            margin="dense"
            name="categoryId"
            label="Select Category"
            fullWidth
            value={formData.categoryId}
            onChange={handleChange}
          >
            {categories.map((obj, k) => (
              <MenuItem key={k} value={obj._id}>
                {obj.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            name="question"
            label="Write your question"
            fullWidth
            value={formData.question}
            onChange={handleChange}
          />
          <FormControl component="fieldset" margin="normal" fullWidth>
            <RadioGroup
              name="options"
              value={formData.correctAnswer}
              onChange={(e) => {
                const updatedOptions = formData.options.map((option, index) => ({
                  ...option,
                  isCorrect: option.text === e.target.value,
                }));
                setFormData((prev) => ({
                  ...prev,
                  options: updatedOptions,
                  correctAnswer: e.target.value,
                }));
              }}
            >
               <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} marginBottom={2}>
                <div>Write the options</div>
                <div>Choose correct answer</div>
              </Stack>

              {formData.options.map((option, index) => (
                <Box key={index} display="flex" alignItems="center" gap={2} my={1}>
                  <TextField
                    label={choice[index]}
                    fullWidth
                    value={option.text}
                    onChange={(e) => handleOptionChange(index, 'text', e.target.value)}
                  />
                  <FormControlLabel
                    value={option.text}
                    control={<Radio />}
                  />
                </Box>
              ))}
            </RadioGroup>
          </FormControl>
          <>
          {( user?.role === 'admin' || user?.role === 'superadmin') && (
            <TextField
            select
            margin="dense"
            name="status"
            label="Status"
            fullWidth
            value={formData.status}
            onChange={handleChange}
          >
            {statuses.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </TextField>
          )}
          </>
          <TextField
            margin="dense"
            name="refLink"
            label="Reference Link"
            fullWidth
            value={formData.refLink}
            onChange={handleChange}
          />
          {/* <TextField
            select
            margin="dense"
            name="difficulty"
            label="Difficulty"
            fullWidth
            value={formData.difficulty}
            onChange={handleChange}
          >
            {difficulties.map((level) => (
              <MenuItem key={level} value={level}>
                {level}
              </MenuItem>
            ))}
          </TextField> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog open={confirmationOpen} onClose={handleCancelConfirmation}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>Are you sure you want to proceed with this action?</DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConfirmation} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} variant="contained" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MCQManagement;