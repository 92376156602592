import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
// import './style.css'; // Make sure to create this CSS file

const InvitePage = () => {

  const { name, userId } = useParams();

  const pageUrl = 'https://play.google.com/store/apps/details?id=com.orbitcompetition.quizgame&hl=en&refBy='+userId; // Your page URL
  const imageUrl = 'https://server.orbitcompetition.com/img/invite.png'; // Image URL for preview
  const title = `${name} invites you to join the Orbit Competition to win $10000`;
  const description = 'This is an amazing competition platform for learning, participating, and winning a prize that you would love to share. Check it out!';

  useEffect(() => {
    const metaDescription = document.querySelector('meta[name="description"]');

    if (metaDescription) {
      // Update the existing meta description tag
      metaDescription.setAttribute('content', title);
    } else {
      // Create a new meta description tag if one doesn't exist
      const newMetaTag = document.createElement('meta');
      newMetaTag.setAttribute('name', 'description');
      newMetaTag.setAttribute('content', description);
      document.head.appendChild(newMetaTag);
    }
  }, []);

  return (
    <div className="">
        <div>

      <Helmet>
        {/* Basic Meta Tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={pageUrl} />

        {/* Open Graph Meta Tags for Facebook, LinkedIn, etc. */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={pageUrl} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imageUrl} />
      </Helmet>
      </div>
    </div>
  );
};

export default InvitePage;
