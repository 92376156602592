import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "state";
import authReducer from "./state/authSlice";
import { Provider } from "react-redux";
import { setupListeners } from "@reduxjs/toolkit/query";
import { api } from "state/api";
import { mcqApi } from "state/mcqApi";
import { adsApi } from "state/adsApi";
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap/dist/js/bootstrap.js'

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import 'react-confirm-alert/src/react-confirm-alert.css'; 

const store = configureStore({
  reducer: {
    global: globalReducer,
    auth: authReducer,
    [api.reducerPath]: api.reducer,
    [mcqApi.reducerPath]: mcqApi.reducer,
    [adsApi.reducerPath]: adsApi.reducer,
  },
  middleware: (getDefault) => 
    getDefault()
      .concat(api.middleware)
      .concat(mcqApi.middleware)
      .concat(adsApi.middleware),
});
setupListeners(store.dispatch);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer />
      <App/>
    </Provider>
  </React.StrictMode>
);
