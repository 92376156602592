import React,{ useState, useEffect } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  Rating,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useGetCustomersQuery,useGetProductsQuery } from "state/api";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, useLocation } from 'react-router-dom'
// import BackButton from "components/BackButton";

const User = () => {
  const theme    = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [cardTitle,setCardTitle] = useState([
    'General MCQ : 1',
    'Math MCQ    : 1',
    'English MCQ : 1',
    'Islamic MCQ : 1',
    'Science MCQ : 1',
    'Ludo MCQ    : 1',
    'Carram MCQ  : 1',
    'Chees  : 1',
    'Sports MCQ  : 1',
  ])
  const {name,email,city,country,occupation,phoneNumber} = location.state;
  const { data }    = useGetCustomersQuery();
  const productList = useGetProductsQuery();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");

  return (
    <Box sx={{ padding:{ xs:1, md:3} }}>
      {/* <BackButton path='/customers' /> */}
      <Header title="User" subtitle="User Information" />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12" style={{columnCount: 2,columnRule: '2px solid green',columnGap: '50px'}}>
              <p><strong>Name:</strong> {name}</p>
              <p><strong>Email:</strong> {email}</p>
              <p><strong>Phone:</strong> {phoneNumber}</p> 
              <p><strong>Country:</strong> {country}</p> 
            </div>
          </div>
        </div>

        <div className="container-fluid mt-5">
          <Header title="" subtitle="User Labels" />
          <div className="row mt-3">
            {
              cardTitle.map((title,index)=>{
                return(
                  <div className="col-md-4 mb-3" key={index}>
                    <div className="card shadow-sm" style={{backgroundColor: theme.palette.background.alt,color: theme.palette.text.primary}}>
                      <div className="card-body">
                        <h5 className="card-title">{title}</h5>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>

      </Box>
    </Box>
  );
};

export default User;