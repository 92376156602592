import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";

import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard";
import Categories from "scenes/categories";
import Customers from "scenes/customers";
import User from "scenes/user";
import Transactions from "scenes/transactions";
import Geography from "scenes/geography";
import Overview from "scenes/overview";
import Daily from "scenes/daily";
import Monthly from "scenes/monthly";
import Breakdown from "scenes/breakdown";
import Admin from "scenes/admin";
import Ads from "scenes/ads";
import Performance from "scenes/performance";
import Login from "scenes/login";
import EditCustomer from "scenes/customers/EditCustomer";
import ReleaseMessage from "scenes/ReleaseMessage";
import NotFound from "scenes/NotFound";
import Teachers from "scenes/teachers";
import CategoryLevels from "scenes/categories/CategoryLevels";
import CategoryLevelUsers from "scenes/categories/CategoryLevelUsers";
import MCQManagement from "scenes/questions";
import Profile from "scenes/profile";
import PrivateRoute from "scenes/login/privateRoute";
import PrivacyPolicyPage from "scenes/FrontPages/PrivacyPolicyPage";
import InvitePage from "scenes/FrontPages/InvitePage";
import { Helmet } from "react-helmet";


function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const user = useSelector((state) => state.auth.user);
  // console.log(user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return (
    <div className="app">
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
                <Routes>

                  { isAuthenticated ? (
                    <Route element={<Layout />}>
                      
                      <Route path="/securelogin" element={<Dashboard/>} />
                      { 
                      user?.role === "superadmin" ? (
                        <>
                         <Route path="/dashboard" element={<Dashboard />} />
                         <Route path="/categories" element={<Categories />} />
                         <Route path="/category/levels" element={<CategoryLevels />} />
                         <Route path="/category/level/users/" element={<CategoryLevelUsers />} />
                         <Route path="/customers" element={<Customers />} />
                         <Route path="/user" element={<User />} />
                         <Route path="/edit-customer" element={<EditCustomer />} />
                         <Route path="/teachers" element={<Teachers />} />
                         <Route path="/admin" element={<Admin />} />
                         <Route path="/profile" element={<Profile />} />
                         <Route path="/mcq-management" element={<MCQManagement />} />
                         <Route path="/ads" element={<Ads/>} />
                        </>
                     ): user?.role === "admin" ? (
                         <>
                          <Route path="/dashboard" element={<Dashboard />} />
                          <Route path="/categories" element={<Categories />} />
                          <Route path="/category/levels" element={<CategoryLevels />} />
                          <Route path="/category/level/users/" element={<CategoryLevelUsers />} />
                          <Route path="/customers" element={<Customers />} />
                          <Route path="/user" element={<User />} />
                          <Route path="/edit-customer" element={<EditCustomer />} />
                          <Route path="/teachers" element={<Teachers />} />
                          <Route path="/admin" element={<Admin />} />
                          <Route path="/profile" element={<Profile />} />
                          <Route path="/mcq-management" element={<MCQManagement />} />
                          <Route path="/ads" element={<Ads/>} />
                         </>
                      ): user?.role === "teacher" ? (
                         <>
                          <Route path="/dashboard" element={<Dashboard />} />
                          <Route path="/profile" element={<Profile />} />
                          <Route path="/mcq-management" element={<MCQManagement />} />
                         </>
                      ):(
                        <>
                         <Route path="/dashboard" element={<Dashboard />} />
                         <Route path="/profile" element={<Profile />} />
                        </>
                      )}
                    </Route>
                  ):(
                    <></>
                  )}
                  <Route path="/" element={<ReleaseMessage/>} />
                  <Route path="/securelogin" element={<Login/>} />
                  <Route path="/privacy-policy" element={<PrivacyPolicyPage/>} />
                  <Route path="/invite/:name/:userId" element={<InvitePage/>} />
                  <Route path="*" element={<NotFound />} />
                  
              </Routes>   
            
          </ThemeProvider>
        </BrowserRouter>

    </div>
  );
}

export default App;