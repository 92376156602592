import React,{ useState, useEffect } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  Rating,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useGetCustomersQuery,useGetProductsQuery } from "state/api";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, useLocation } from 'react-router-dom'
// import BackButton from "components/BackButton";

const CategoryLevels = () => {
  const theme    = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [levels, setLevels] = useState([
    {level: 1, users: 1, message: ''},
    {level: 2, users: 0, message: ''},
    {level: 3, users: 0, message: ''},
    {level: 4, users: 0, message: ''},
    {level: 5, users: 0, message: ''},
    {level: 6, users: 0, message: ''},
    {level: 7, users: 0, message: ''},
    {level: 8, users: 0, message: ''},
    {level: 9, users: 0, message: ''},
    {level: 10, users: 0, message: ''},
    {level: 11, users: 0, message: ''},
    {level: 12, users: 0, message: ''},
    {level: 13, users: 0, message: ''},
    {level: 14, users: 0, message: ''},
    {level: 15, users: 0, message: ''},
    {level: 16, users: 0, message: ''},
    {level: 17, users: 0, message: ''},
    {level: 18, users: 0, message: ''},
    {level: 19, users: 0, message: ''},
    {level: 20, users: 0, message: ''},
    {level: 21, users: 0, message: ''},
    {level: 22, users: 0, message: ''},
    {level: 23, users: 0, message: ''},
    {level: 24, users: 0, message: ''},
    {level: 25, users: 0, message: ''},

  ])
  const {name, id} = location.state;
  const { data }    = useGetCustomersQuery();
  const productList = useGetProductsQuery();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");

  const goLevelUsers = (e) => {
    console.log(e);
    navigate('/category/level/users', {state:e});
  }


  return (
    <Box m="1.5rem 2.5rem">
      {/* <BackButton path='/customers' /> */}
      <Header title={`Levels of ${name}`} subtitle="Level's report based on selected categories" />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        

        <div className="container-fluid mt-5">
          <div className="row mt-3">
            {
              levels.map((item,index)=>{
                return(
                  <div className="col-md-4 mb-3" key={index} onClick={()=>goLevelUsers({com_type_name: name, com_type_id: id, level: item.level})}>
                    <div className="card shadow-sm" style={{backgroundColor: theme.palette.background.alt,color: theme.palette.text.primary}}>
                      <div className="card-body">
                        <h5 className="card-title">Level {item.level}</h5>
                        <div className="card-subtitle">{item.users} user(s)</div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>

      </Box>
    </Box>
  );
};

export default CategoryLevels;