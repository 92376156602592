import React,{ useState, useEffect } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  Rating,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useGetCustomersQuery,useGetProductsQuery } from "state/api";
import Header from "components/Header";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, useLocation } from 'react-router-dom'
// import BackButton from "components/BackButton";

const CategoryLevelUsers = () => {
  const theme    = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const {data, isLoading} = useGetCustomersQuery();
  const {com_type_name, com_type_id, level} = location.state;
  const isNonMobile = useMediaQuery("(min-width: 1000px)");

  return (
    <Box m="1.5rem 2.5rem">
      {/* <BackButton path='/customers' /> */}
      <Header title={`Users of ${com_type_name}`} subtitle={`listing all the users from level ${level} and ${com_type_name}`} />
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        

        <div className="container-fluid mt-5">
          <div className="row mt-3">
            {
              data.map((item,index)=>{
                return(
                  <div className="col-md-3 mb-3" key={index}>
                    <div className="card shadow-sm" style={{backgroundColor: theme.palette.background.alt,color: theme.palette.text.primary}}>
                      <div className="card-body">
                        <h5 className="card-title">Name: {item.name}</h5>
                        <div className="card-subtitle">Email: {item.email}</div>
                        <div className="card-subtitle">Phone: {item.phone}</div>
                        <div className="card-subtitle">Level: {level}</div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>

      </Box>
    </Box>
  );
};

export default CategoryLevelUsers;