import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const adsApi = createApi({
  reducerPath: "adsApi",
  baseQuery: fetchBaseQuery({ 
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      // Get the token from the Redux state (or another storage mechanism)
      // console.log('state ', getState());
      const token = getState()?.auth?.authToken; 
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Ad"],
  endpoints: (builder) => ({
    // Fetch all ads
    getAds: builder.query({
      query: () => "api/getAds",
      providesTags: ["Ad"],
    }),
    // Fetch a single ad by ID
    getAdById: builder.query({
      query: (id) => `api/getAdById/${id}`,
      providesTags: (result, error, id) => [{ type: "Ad", id }],
    }),
    // Create a new ad
    createAd: builder.mutation({
      query: (formData) => ({
        url: "api/createAd",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["Ad"],
    }),
    // Update an ad by ID
    updateAd: builder.mutation({
      query: ({ id, formData }) => ({
        url: `api/updateAd/${id}`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["Ad"],
    }),
    // Delete an ad by ID
    deleteAd: builder.mutation({
      query: (id) => ({
        url: `api/deleteAd/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Ad"],
    }),
  }),
});

export const {
  useGetAdsQuery,
  useGetAdByIdQuery,
  useCreateAdMutation,
  useUpdateAdMutation,
  useDeleteAdMutation,
} = adsApi;