import React, { useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  Button,
  TextField,
  Divider,
  Grid,
  Paper,
  MenuItem,
} from '@mui/material';

const UserProfileDashboard = () => {
  const [editMode, setEditMode] = useState(false);
  const [profileData, setProfileData] = useState({
    avatar: '',
    fullName: 'John Doe',
    email: 'john.doe@example.com',
    phone: '+1234567890',
    address: '123 Main St, Springfield, USA',
    role: 'Admin',
  });

  const roles = ['Admin', 'User', 'Editor'];

  const handleEditToggle = () => {
    setEditMode((prev) => !prev);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAvatarUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfileData((prev) => ({ ...prev, avatar: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    console.log('Profile updated:', profileData);
    setEditMode(false);
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', bgcolor: '#f5f5f5' }}>
      {/* Main Content */}
      <Box sx={{ flex: 1, p: 4 }}>
        <Typography variant="h4" gutterBottom>
          User Profile
        </Typography>
        <Divider sx={{ mb: 4 }} />

        {/* Profile Section */}
        <Paper elevation={3} sx={{ p: 4 }}>
          <Grid container spacing={2}>
            {/* Avatar Section */}
            <Grid item xs={12} sm={4} md={3}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Avatar
                  src={profileData.avatar}
                  sx={{ width: 100, height: 100 }}
                  alt="Profile Avatar"
                />
                {editMode && (
                  <Button variant="contained" component="label">
                    Upload
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={handleAvatarUpload}
                    />
                  </Button>
                )}
              </Box>
            </Grid>

            {/* Profile Details */}
            <Grid item xs={12} sm={8} md={9}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                  label="Full Name"
                  name="fullName"
                  value={profileData.fullName}
                  onChange={handleChange}
                  fullWidth
                  disabled={!editMode}
                />
                <TextField
                  label="Email"
                  name="email"
                  value={profileData.email}
                  onChange={handleChange}
                  fullWidth
                  disabled={!editMode}
                />
                <TextField
                  label="Phone"
                  name="phone"
                  value={profileData.phone}
                  onChange={handleChange}
                  fullWidth
                  disabled={!editMode}
                />
                <TextField
                  label="Address"
                  name="address"
                  value={profileData.address}
                  onChange={handleChange}
                  fullWidth
                  disabled={!editMode}
                />
                <TextField
                  select
                  label="Role"
                  name="role"
                  value={profileData.role}
                  onChange={handleChange}
                  fullWidth
                  disabled={!editMode}
                >
                  {roles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>
          </Grid>

          {/* Action Buttons */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              mt: 4,
            }}
          >
            {editMode ? (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSave}
                >
                  Save
                </Button>
                <Button variant="outlined" onClick={handleEditToggle}>
                  Cancel
                </Button>
              </>
            ) : (
              <Button variant="contained" onClick={handleEditToggle}>
                Edit Profile
              </Button>
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default UserProfileDashboard;